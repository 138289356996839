import { observer, inject } from "mobx-react"
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Container,
  Menu,
  Header,
  Icon
} from 'semantic-ui-react'
import ScrollToTopOnMount from './ScrollToTopOnMount'

class PageTechnical extends Component {

  handleItemClick = (e, { name }) => {

  };

  render() {
    const { shop } = this.props
    const { openPlatingPage, openMaterialPage, openDepthOfThreadFPage, openDepthOfThreadMFPage, openTermsOfSalePage,  openTorqueValuesPage } = shop.view;

    return(
      <div>
        <Container>
          <Header as='h2' dividing textAlign='center'>
            <Icon name='info circle' />
            <Header.Content>Technical Information</Header.Content>
          </Header>

          <Menu fluid vertical>
            <Menu.Item onClick={openPlatingPage}>Plating Specifications</Menu.Item>
            <Menu.Item onClick={()=> window.open("/pdf/productioninfo.pdf", "_blank")} name='production'>Production Information</Menu.Item>
            <Menu.Item onClick={()=> window.open("/pdf/materialinfo.pdf", "_blank")} name='materialinfo'>Material Information</Menu.Item>
            <Menu.Item onClick={openMaterialPage} name='material'>Material Specifications</Menu.Item>
            <Menu.Item onClick={openDepthOfThreadFPage} name='depthofthreadf'>Depth of Threads - Female Standoffs</Menu.Item>
            <Menu.Item onClick={openDepthOfThreadMFPage} name='depthofthreadmf'>Depth of Threads - Male-Female Standoffs</Menu.Item>
            <Menu.Item onClick={openTorqueValuesPage} name='torquevalues'>Torque Values</Menu.Item>
            <Menu.Item onClick={()=> window.open("/pdf/rohs.pdf", "_blank")} name='rohs'>RoHS Statement</Menu.Item>
            <Menu.Item onClick={()=> window.open("/pdf/reach.pdf", "_blank")} name='reach'>REACH Statement</Menu.Item>
            <Menu.Item onClick={()=> window.open("/pdf/russiadeclaration.pdf", "_blank")} name='russiadeclaration'>Russia Declaration Statement</Menu.Item>
            <Menu.Item onClick={()=> window.open("/pdf/prop65.pdf", "_blank")} name='prop65'>Proposition 65 Statement</Menu.Item>
            <Menu.Item onClick={openTermsOfSalePage} name='termsofsale'>Terms of Sale</Menu.Item>
            <Menu.Item onClick={()=> window.open("/pdf/ISO.pdf", "_blank")} name='iso9001'>ISO 9001:2015 Certificate</Menu.Item>
            <Menu.Item onClick={()=> window.open("/pdf/cbam.pdf", "_blank")} name='cbam'>CBAM Conformance</Menu.Item>
          </Menu>
        </Container>
        <ScrollToTopOnMount />
      </div>
    )
  }
}

export default inject("shop")(observer(PageTechnical))